<template>
  <div v-if="checkPermission('view', 'action', 'url-trackings')">
    <b-row class="mb-2">
      <b-card class="w-100 m-2 p-3" no-body>
        <div>
          <b-row align-v="center">
            <b-col>
              <h4 class="card-title mb-0 text-primary">
                {{ $t('short_link.details.url_details') }}</h4>
            </b-col>
            <b-col align="end">
              <b-button class="m-0" variant="primary" @click="toAllCampaigns"><i class="fas fa-arrow-left"></i>
                {{ $t('short_link.details.url_list') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-row>
    <b-card class="mb-3">
      <b-row>
        <b-col>
          <span class="font-size-16 font-weight-600">
            {{ $t('short_link.details.monthly_clicked_details') }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <span>
            {{ $t('short_link.details.name_URL') }} : {{ linkDetail.title }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div class="flex-center" style="max-width: 100%">
            <span>
              {{ $t('short_link.details.full_URL') }} :
            </span>
            <a :href="linkDetail.main_link"
               style="color: #3CADDE; text-decoration: underline; max-width: 85%; width: auto;" target="_blank">
              <div class="ellipsis">{{ linkDetail.main_link }}</div>
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div class="flex-center">
            <span>
              {{ $t('short_link.details.shot_URL') }} : {{ linkDetail.short_link }}
            </span>
            <div class="ml-2 copy-icon" @click="copyText(linkDetail.short_link)">
              <i class="fas fa-copy"/>
            </div>
          </div>
          <div class="mt-3 mb-3" style="width: 100%; background: #E9E9E9; height: 1px;"></div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="6" align-self="center">
          <div class="flex-center" style="margin-bottom: 0.2rem; gap: 24px;">
            <span class="current-date">{{ getMonthLang() }} {{ current_chart_data?.date_time[0]?.getFullYear() }}</span>
            <div class="calendar-prev-next">
              <div
                  class="calendar-prev"
                  @click="!disable_pass_one_year && clickedArrow(-1)"
                  :style="`${iconMaskURL('prev')} ${disable_pass_one_year ? 'background-color:#9F9F9F; cursor: not-allowed;':''}`"

              >
              </div>
              <div
                  class="calendar-next"
                  @click="!disable_next_month && clickedArrow(1)"
                  :style="`${iconMaskURL('next')} ${disable_next_month ? 'background-color:#9F9F9F; cursor: not-allowed;':''}`"
              >
              </div>
            </div>
            <button type="button" class="btn btn-gradient ml-2" :disabled="is_current_month" @click="currentMonth">
              {{ $t('short_link.details.current_month') }}
            </button>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="flex-center" style="justify-content: flex-end; gap: 16px">
            <select-filter-component block_first_template v-model="search_chart.month" :options="month_optional"/>
            <select-filter-component block_first_template v-model="search_chart.year" :options="year_list"/>
            <button
                type="button"
                class="btn btn-gradient ml-2"
                @click="setYearBeforeSearch"
                :disabled="disable_search_by_month_and_year"
            >
              {{ $t('common.Search') }}
              <b-spinner small v-show="is_loading"/>
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('short_link.details.total_clicked') }} :
          {{ changeClickedString(linkDetail.total_Clicked) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pb-2">
          <div style="height: 430px">
            <vue-apex-charts width="100%" height="100%" type="line" :options="listChart"
                             :series="series_data"></vue-apex-charts>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <span class="font-size-16 font-weight-600">
            {{ $t('short_link.details.clicked_details') }}
          </span>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col lg="4" md="5" sm="6">
          <b-form-group label-cols="12" :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="search_date.date_time" :disabledRange="disabledDateRange"/>
          </b-form-group>
        </b-col>
        <b-col align-self="end">
          <div style="margin-bottom: 1rem;">
            <button type="button" class="btn waves-effect waves-light btn-gradient ml-2" @click="sortDataAtTableByDate">
              {{ $t('common.Search') }}
              <b-spinner small v-show="is_loading"/>
            </button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col align="end" class="mb-2">
          <b-button v-if="checkPermission('export', 'action', 'url-trackings')" variant="primary" class="mr-2"
                    @click="exportUrlTracking">
            <i class="fas fa-file-excel mr-1"/>
            {{ $t("button.export") }}
            <b-spinner small v-show="is_loading_export"/>
          </b-button>
        </b-col>
      </b-row>
      <b-table responsive class="mb-0" :items="data_table" :fields="field" :busy="isBusy" :empty-text="$t('empty')"
               show-empty>
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(date)="data">
          {{ data.item.date }}
        </template>
        <template #cell(total_ios)="data">
          {{ changeClickedString(data.item.total_ios) }}
        </template>
        <template #cell(total_android)="data">
          {{ changeClickedString(data.item.total_android) }}
        </template>
        <template #cell(total_other)="data">
          {{ changeClickedString(data.item.total_other) }}
        </template>
        <template #cell(total_click)="data">
          <span class="font-weight-700 text-primary">
            {{ changeClickedString(data.item.total_click) }}
          </span>
        </template>
      </b-table>
      <!-- <b-row>
        <div v-if="search_date.$skip" class="ml-auto">
          <b-pagination v-model="search_date.$skip" :total-rows="totalRows" :per-page="search_date.$limit" align="fill" class="my-0"
            size="sm" @input="getUrlDetails" />
        </div>
      </b-row> -->
    </b-card>
    <div>
      <b-modal id="modal-export" ok-variant="info" hide-footer no-close-on-backdrop>
        <template #modal-header="{ close }">
          <h5></h5>
          <i class="fas fa-times hover-icon action-button" @click="close"></i>
        </template>
        <div align="center" v-if="!exportSuccess">
          <b-spinner class="mt-5 mb-1" style="width: 4rem; height: 4rem" label="Large Spinner" variant="warning"
                     type="grow">
          </b-spinner>
          <h3 v-html="$t('processing')" class="mb-3"></h3>
        </div>
        <div v-if="exportSuccess">
          <div align="center" v-if="exportSuccess && step_success">
            <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
            <h3 v-html="$t('success')" class="mb-3"></h3>
            <div v-for="items in fileExport" style="width: 80%;">
              <a :href="items" class="ellipsis">
                <div class="ellipsis">{{ items }}</div>
              </a>
              <hr/>
            </div>
          </div>
          <div align="center" v-if="!exportSuccess && step_success">
            <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
            <h3 v-html="$t('failed')" class="mb-3"></h3>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import shortLinkApi from "@/repository/shortLinkApi";
import masterData from "@/common/masterData.json";

import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import SelectComponent from "@/components/common/selectComponent";
import alert from "@/common/alert";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import EmptyTable from "@/components/common/emptyTable.vue";
import functions from "@/common/functions";
import {co} from "@fullcalendar/core/internal-common";
import {da} from "date-fns/locale";

export default {
  name: "urlDetail",
  components: {
    DateTimeRangeComponent,
    SelectComponent,
    InputComponent,
    VueApexCharts,
    EmptyTable,
    SelectFilterComponent
    // CustomPackage
  },
  computed: {
    is_current_month() {
      if (!this.current_chart_data?.date_time[0]) {
        return false;
      }
      const d = new Date();
      const current_month = d.getMonth() + 1;
      const current_year = d.getFullYear();
      const date = this.current_chart_data.date_time[0];
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return current_month == month && current_year == year;
    },
    disable_next_month() {
      if (!this.current_chart_data?.date_time[0]) {
        return false;
      }
      const date = this.current_chart_data?.date_time[0]
      const now_date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      return now_date > new Date()
    },
    disable_pass_one_year() {
      const date = new Date()
      const lastYear = new Date((date.getFullYear() - 1).toString(), date.getMonth(), date.getDate());
      return lastYear >= this.current_chart_data?.date_time[0]
    },
    disable_search_by_month_and_year() {
      const d = new Date();
      const date = new Date(this.search_chart.year, this.search_chart.month, d.getDate());

      const lastYear = new Date((d.getFullYear() - 1).toString(), d.getMonth(), d.getDate());
      return date > d || lastYear > date;
    },
  },
  data() {
    return {
      ref_id: "",
      linkDetail: {
        title: "",
        main_link: "",
        short_url: "",
        total_Clicked: 0,
      },
      current_chart_data: {
        date_time: [],
      },
      search_chart: {
        date_time: [],
        month: "",
        year: "",
      },
      current_show_month: "",
      disabledDateRange: [],
      search_date: {
        date_time: [],
      },
      isBusy: false,
      field: [
        {key: "date", label: "short_link.list.create_at"},
        {key: "total_ios", label: "short_link.list.click_from_ios", class: "text-center"},
        {key: "total_android", label: "short_link.list.click_from_android", class: "text-center"},
        {key: "total_other", label: "short_link.list.click_other", class: "text-center"},
        {key: "total_click", label: "short_link.list.total_click", class: "text-center"},
      ],
      data_list: [],
      data_table: [],
      // totalRows: 1,
      pageOptions: [10, 15, 50, 100],
      is_loading: false,
      is_loading_delete: false,
      is_loading_export: false,
      exportSuccess: false,
      step_success: false,
      year_list: [],
      series_data: [],
      month_optional: masterData.monthList,
      listChart: {
        colors: ["#E300B1", "#18A0FB", "#FF5C00"],
        chart: {
          height: "100%",
          type: "line",
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
        },
        yaxis: {
          decimalsInFloat: 0,
          tickAmount: 5,
          axisBorder: {
            show: false,
            height: 1,
          },
        },
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const total = series[0][dataPointIndex] + series[1][dataPointIndex] + series[2][dataPointIndex]
            const cycle_point = "width: 14px; height: 14px; border-radius: 14px;"
            const style_label = "min-width: 54px;"
            return `
            <div class="pt-1 pb-1 pl-3 pr-3" style="background-color: #EDEFF1">
              <div class="font-weight-700 font-size-16">${dataPointIndex + 1}</div>
            </div>
            <div class="pt-2 pb-2 pl-3 pr-3">
              <div class=flex-center>
                <div class="mb-1 mr-2" style="${cycle_point} background-color: #E300B1"></div>
                <div class="flex-center">
                  <div style="${style_label}">IOS</div>:
                  <div class="ml-1">${series[0][dataPointIndex].toLocaleString()}</div>
                </div>
              </div>
              <div class=flex-center>
                <div class="mb-1 mr-2" style="${cycle_point} background-color: #18A0FB"></div>
                <div class="flex-center">
                  <div style="${style_label}">Android</div>:
                  <div class="ml-1">${series[1][dataPointIndex].toLocaleString()}</div>
                </div>
              </div>
              <div class=flex-center>
                <div class="mb-1 mr-2" style="${cycle_point} background-color: #FF5C00"></div>
                <div class="flex-center">
                  <div style="${style_label}">Other</div>:
                  <div class="ml-1">${series[2][dataPointIndex].toLocaleString()}</div>
                </div>
              </div>
              <div class=flex-center>
                <div class="mb-1 mr-2" style="${cycle_point}"></div>
                <div class="flex-center">
                  <div style="${style_label}">Total</div>:
                  <div class="ml-1 font-weight-700 text-primary">${total.toLocaleString()}</div>
                </div>
              </div>
            </div>
            `
          }
        },
        legend: {
          horizontalAlign: "center",
          itemMargin: {
            horizontal: 24,
          },
        }
      },
    }
  },
  mounted() {
    this.ref_id = this.$route.query.ref_id
    if (this.ref_id) {
      try {
        this.setYearsOptional()
      } catch (error) {
        this.$router.push("/Short-Link")
      } finally {
        this.setFirstDateOfMonth()
        this.getUrlDetails()
      }
    } else {
      this.$router.push("/Short-Link")
    }
  },
  methods: {
    iconMaskURL(name) {
      return functions.iconMaskURL(name)
    },
    sortDataAtTableByDate() {
      const startDate = new Date(this.search_date.date_time[0]);
      const endDate = new Date(this.search_date.date_time[1]);

      const parseDate = str => {
        // "12/08/2023"
        const [day, month, year] = str.split("/");
        return new Date(year, month - 1, day);
      };

      const isInDateRange = date => date >= startDate && date <= endDate;

      this.data_table = this.data_list
          .filter(item => {
            const itemDate = parseDate(item.date);
            return isInDateRange(itemDate);
          })
          .map(item => {
            const itemDate = parseDate(item.date);
            return {
              ...item,
              date: moment(itemDate).format("DD/MM/YYYY")
            };
          });
    },
    setFirstDateOfMonth(first_date, last_date) {
      // first day of month
      const getFullYear = this.search_chart.date_time[0].getFullYear()
      const getMonth = this.search_chart.date_time[0].getMonth()
      const first_day = first_date ? first_date : new Date(getFullYear, getMonth, 1);
      // last day of month
      const last_day = last_date ? last_date : new Date(getFullYear, getMonth + 1, 0);
      this.search_chart.date_time = [first_day, last_day]
      this.search_date.date_time = [first_day, last_day]
      this.current_chart_data.date_time = [first_day, last_day]
    },
    setChartLine() {
      const categories = []
      const total_ios = []
      const total_android = []
      const total_other = []
      this.series_data = [];

      for (let i = 0; i < this.data_list.length; i++) {
        const item = this.data_list[i];
        categories.push(item.date.split("/")[0])
        total_ios.push(item.total_ios)
        total_android.push(item.total_android)
        total_other.push(item.total_other)
      }
      this.listChart = {
        xaxis: {
          categories: categories
        },
      };
      this.series_data.push({
        name: "IOS",
        data: total_ios
      })
      this.series_data.push({
        name: "Android",
        data: total_android
      })
      this.series_data.push({
        name: "Other",
        data: total_other
      })
    },
    setYearsOptional() {
      const d = new Date()
      const currentYear = d.getFullYear()
      this.search_chart.date_time = [
        new Date(moment(d).format("YYYY-MM-DD") + "T00:00:00"),
        new Date(moment(d).format("YYYY-MM-DD") + "T23:59:59")
      ]
      this.search_chart.year = currentYear.toString();
      this.search_chart.month = masterData.monthList[d.getMonth()].value;
      for (let i = 2022; i <= currentYear; i++) {
        const year = i.toString()
        this.year_list.push({
          value: year,
          en: year,
          th: year,
          cn: year,
          id: year,
          my: year,
          vn: year
        })
      }
    },
    getMonthLang() {
      if (this.current_chart_data.date_time[0]) {
        const month = this.current_chart_data.date_time[0].getMonth()
        const lang = this.$i18n.locale ? this.$i18n.locale : 'en'
        return masterData.monthList[month][lang]
      }
    },
    setDateDisableOnTable() {
      const date = this.current_chart_data.date_time[0]
      const dateBefore = new Date(date.getFullYear(), date.getMonth(), 1)
      const dateAfter = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      this.disabledDateRange = [dateBefore, dateAfter]
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    setYearBeforeSearch() {
      const first_day = new Date(this.search_chart.year, this.search_chart.month, 1);
      const last_day = new Date(this.search_chart.year, this.search_chart.month + 1, 0);
      this.getUrlDetails(first_day, last_day);
    },
    getUrlDetails(first_day, last_day) {
      this.is_loading = true;
      this.isBusy = true;
      if (first_day && last_day) {
        this.setFirstDateOfMonth(first_day, last_day);
      } else {
        this.setFirstDateOfMonth();
      }
      const search = {
        date_time: this.search_date.date_time,
        ref_id: this.ref_id,
      }
      shortLinkApi.getUrlTrackingList(search).then(resp => {
        this.data_list = resp.data
        this.data_table = resp.data;
        this.linkDetail = resp.linkDetail
        let total = 0;
        for (let i = 0; i < resp.data.length; i++) {
          const item = this.data_list[i];
          total += item.total_click;
        }
        this.linkDetail.total_Clicked = total;
        this.search_date.$skip = resp.skip || 1
        this.setChartLine()
      }).finally(() => {
        this.is_loading = false
        this.isBusy = false;
        setTimeout(() => {
          this.setDateDisableOnTable()
        }, 0)
      })
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.$bvToast.toast(`Copy Successful`, {
        title: 'Notice!',
        // toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2000
      })
    },
    changeClickedString(data) {
      let num = data ? Number(data) : 0
      return num ? num.toLocaleString() : "-"
    },
    currentMonth() {
      const d = new Date();
      const date = new Date(d.getFullYear(), d.getMonth(), 1);
      this.search_chart.date_time = [
        new Date(moment(date).format("YYYY-MM-DD") + "T00:00:00"),
        new Date(moment(date).format("YYYY-MM-DD") + "T23:59:59")
      ]
      this.search_chart.month = d.getMonth();
      this.search_chart.year = d.getFullYear();
      this.getUrlDetails();
    },
    exportUrlTracking() {
      const text_search = this.search_date
      let date_from = null;
      let date_to = null;
      if (text_search?.date_time) {
        date_from = moment(text_search?.date_time[0]).format('YYYY-MM-DD') + 'T00:00:00' + '.000Z'
        date_to = moment(text_search?.date_time[1]).format('YYYY-MM-DD') + 'T23:59:59' + '.999Z'
      }
      const data = {
        ref_id: this.ref_id,
        date_from: date_from,
        date_to: date_to,
        file_type: "csv",
      }
      this.is_loading_export = true
      if (this.is_loading_export) {
        this.$bvModal.show('modal-export')
        shortLinkApi.exportDailyTransaction(_.cloneDeep(data)).then(response => {
          if (response?.codeSYS === '001') {
            this.fileExport = response?.url_files
            this.exportSuccess = true
            this.step_success = true
          } else {
            this.exportSuccess = false
            this.step_success = false
          }
        }).catch(err => {
          this.$bvModal.hide('modal-export')
          alert.findFailed(this, err.response.data.message)
        }).finally(() => {
          this.is_loading_export = false
        })
      }

    },
    toAllCampaigns() {
      this.$router.push("/Short-Link");
    },
    clickedArrow(value) {
      const d = new Date(this.current_chart_data.date_time[0].getFullYear(), this.current_chart_data.date_time[0].getMonth() + value, 1)
      let month = this.current_chart_data.date_time[0].getMonth() + value;
      let year = this.current_chart_data.date_time[0].getFullYear();
      if (month === 12) {
        month = 0
        year = year + 1
      } else if (month === -1) {
        month = 11
        year = year - 1
      }
      this.search_chart = {
        date_time: [
          new Date(d.getFullYear(), d.getMonth(), 1),
          new Date(d.getFullYear(), d.getMonth() + 1, 0),
        ],
        month: month,
        year: year,
      }
      this.getUrlDetails()
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.$bvToast.toast(`Copy Successful`, {
        title: 'Notice!',
        // toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2000
      })
    },
  }
}
</script>

<style scoped lang="scss">
.current-date {
  font-size: 24px;
  font-weight: 500;

  @media (max-width: 576px) {
    display: none;
  }
}


.current-date-sm {
  display: none;
  font-size: 18px;
  font-weight: 500;
}

.calendar-prev-next {
  display: flex;
  gap: 48px;
  color: var(--primary-color);

  @media (max-width: 576px) {
    gap: 18px;
  }

  .calendar-next,
  .calendar-prev {
    width: 12px;
    height: 12px;
    color: var(--primary-color) !important;
    cursor: pointer;
  }
}

.tooltip-label-width {
  width: 40px;
  background-color: red;
}
</style>